import { Comparator, moment, Period, Utils } from "revlock-webutils";
import { updateRatablePlan } from "./plan/ApportionedRatablePlan";
import { Codes } from "./ErrorCode";
import RecognitionRule from "./reference/RecognitionRule";
import * as ArrangementUtils from "./ArrangementUtils";
import shortId from "shortid";
import cloneDeep from "lodash.clonedeep";
import ItemType from "./reference/ItemType";
import ModificationType from "./reference/ModificationType";

/**
 * Update to a base PS plan that recognizes the entire amount
 * in the last period and nothing before that. This plan
 * will be subsequently updated when service delivery logs
 * are gathered.
 *
 * @param plan
 * @param productRevenue
 */
export function updateToBaseServicePlan(plan, productRevenue) {
    plan.forEach((p) => {
        p.planAmount = 0.0;
        p.percentRecognized = 0.0;

        delete p.cummulativeTotal;
        delete p.cummulativePercentRecognized;
    });

    if (plan.length > 0) {
        plan[plan.length - 1].percentRecognized = 1.0;
        plan[plan.length - 1].planAmount = productRevenue;
    }
    //a default plan with no amounts in it for proportional performance
    //recognition as service delivery records are consumed
}

/**
 * Update the plan based on service delivery information that is available
 *
 * @param revenueArrangementItem
 * @param salesOrder
 * @param revenueArrangement
 * @param errors
 * @param enableBetaFeatures
 */
export function updatePSPlan(
    revenueArrangementItem,
    salesOrder,
    revenueArrangement,
    errors,
    enableBetaFeatures,
    calendarConfig,
    incrementalPercentComplete,
    orgConfig,
    revenueWithPPA = false,
    computePreModPlan = false
) {
    let productRevenue = revenueArrangementItem.productRevenue;

    if (salesOrder.revenueArrangement) {
        const currentRevenueArrangement = salesOrder.revenueArrangement.find(
            (ra) => ra.id === revenueArrangementItem.revenueArrangementId
        );

        if (
            currentRevenueArrangement &&
            currentRevenueArrangement.isRevenueDelayed
        ) {
            // revenue needs to be delayed
            productRevenue = 0;
        }
    }

    const pspPlanRatable =
        revenueArrangementItem.ssp &&
        revenueArrangementItem.ssp.deliveryRecognitionMethod == "RATABLE";

    updateToBaseServicePlan(revenueArrangementItem.plan, productRevenue);
    //a default plan with no amounts in it for proportional performance
    //recognition as service delivery records are consumed

    if (
        revenueArrangementItem.recognitionRule.id ===
        RecognitionRule.PERCENT_OF_COMPLETE
    ) {
        updatePSPlanPercentageOfCompletion(
            revenueArrangementItem,
            salesOrder,
            errors,
            productRevenue,
            calendarConfig
        );
    } else if (!enableBetaFeatures) {
        updatePSPlan_default(
            revenueArrangementItem,
            salesOrder,
            errors,
            productRevenue,
            calendarConfig
        );
    } else if (pspPlanRatable) {
        updatePSPlanRatable(
            revenueArrangementItem,
            salesOrder,
            errors,
            productRevenue,
            calendarConfig,
            incrementalPercentComplete,
            orgConfig,
            revenueWithPPA
        );
    } else {
        updatePSPlanProportionalPerformance(
            revenueArrangementItem,
            salesOrder,
            revenueArrangement,
            errors,
            productRevenue,
            calendarConfig,
            incrementalPercentComplete,
            orgConfig,
            revenueWithPPA,
            computePreModPlan
        );
    }
}

export function getDisabledPSDEntries(psdLogs) {
    const toReturn = [];

    psdLogs.forEach((log) => {
        if (log.disabled != undefined && log.disabled == true) {
            toReturn.push(log);
        }
    });

    toReturn.sort(function(log1, log2) {
        return moment(log1.period, Utils.PERIOD_FORMAT).diff(
            moment(log2.period, Utils.PERIOD_FORMAT)
        );
    });

    return toReturn;
}

export function getDeliveryLogByPeriod(soiPSDelivery) {
    function getLogsAccumulatedByPeriod(deliveryLog) {
        const groupedByPeriod = Utils.groupByOnArray(
            deliveryLog,
            (E) => E.period
        );
        const psdLogs = [];
        Object.keys(groupedByPeriod).forEach((period) => {
            const psdLog = groupedByPeriod[period];
            const psd = {};
            Object.assign(psd, psdLog[0], {
                unitsDelivered: undefined,
                percentComplete: undefined
            });
            psdLog.forEach((log) => {
                if (log["unitsDelivered"]) {
                    if (psd["unitsDelivered"] == undefined) {
                        psd["unitsDelivered"] = 0;
                    }
                    psd["unitsDelivered"] += log["unitsDelivered"];
                } else if (log["percentComplete"]) {
                    if (psd["percentComplete"] == undefined) {
                        psd["percentComplete"] = 0;
                    }
                    psd["percentComplete"] += log["percentComplete"];
                }
            });

            psdLogs.push(psd);
        });

        psdLogs.sort(function(log1, log2) {
            return moment(log1.period, Utils.PERIOD_FORMAT).diff(
                moment(log2.period, Utils.PERIOD_FORMAT)
            );
        });

        return psdLogs;
    }

    let deliveryLogByPeriod = undefined;
    if (soiPSDelivery.isLegacy == undefined || soiPSDelivery.isLegacy == true) {
        deliveryLogByPeriod = Utils.arrayToObject(
            soiPSDelivery.deliveryLog,
            (e) => e.period
        );
    } else {
        const psdLogs = getLogsAccumulatedByPeriod(soiPSDelivery.deliveryLog);
        //accumulate logs by period

        let previousPSD = 0;
        psdLogs.forEach((currentPsd, index) => {
            if (currentPsd["unitsDelivered"] !== undefined) {
                currentPsd["unitsDelivered"] += previousPSD;
                previousPSD = currentPsd["unitsDelivered"];
            }
        });
        //create a running total for each period

        deliveryLogByPeriod = Utils.arrayToObject(psdLogs, (E) => E.period);
    }

    return deliveryLogByPeriod;
}

export function updatePSPlan_default(
    revenueArrangementItem,
    salesOrder,
    errors,
    productRevenue,
    calendarConfig
) {
    let { professionalServiceDelivery } = salesOrder;

    if (professionalServiceDelivery) {
        let { salesOrderItem, plan } = revenueArrangementItem;
        let soiPSDelivery = professionalServiceDelivery.soiProfessionalServiceDelivery.find(
            (psd) => {
                let itemId =
                    revenueArrangementItem.salesOrderItem.rootId ||
                    revenueArrangementItem.salesOrderItem.id;
                if (psd.salesOrderItemId === itemId) return psd;
            }
        );

        if (soiPSDelivery) {
            let deliveryLogByPeriod = getDeliveryLogByPeriod(
                soiPSDelivery,
                calendarConfig
            );

            let cumulativePercentRecognized = 0;
            for (let planElement of plan) {
                const period =
                    planElement.actgPeriod?.period || planElement.period;
                if (!period) throw new Error("Bad plan state: missing period");

                if (cumulativePercentRecognized === 1) {
                    // we have recognized everything 100%
                    break;
                }

                const deliveryLog = deliveryLogByPeriod[period];
                let percentRecognized = 0.0;
                if (deliveryLog) {
                    let { unitsDelivered, percentComplete } = deliveryLog;

                    if (percentComplete) {
                        percentComplete = isNaN(percentComplete)
                            ? 0.0
                            : Math.abs(percentComplete);
                    } else {
                        unitsDelivered = isNaN(unitsDelivered)
                            ? 0.0
                            : Math.abs(unitsDelivered);
                    }

                    if (percentComplete) {
                        percentRecognized = deliveryLog.percentComplete;
                        if (percentRecognized > 1) {
                            percentRecognized = 1;
                            if (errors) {
                                errors.add(Codes.TRAN_25, {
                                    salesOrder,
                                    salesOrderItem,
                                    unitsLogPosted: deliveryLog.percentComplete,
                                    actualUnitsSold: 1,
                                    period: deliveryLog.period,
                                    extraUnits: deliveryLog.percentComplete - 1
                                });
                            }
                        }
                    } else {
                        if (
                            isNaN(unitsDelivered) ||
                            isNaN(salesOrderItem.quantity)
                        )
                            throw new Error("Invalid Units Delivered");

                        if (unitsDelivered > salesOrderItem.quantity) {
                            if (errors) {
                                errors.add(Codes.TRAN_25, {
                                    salesOrder,
                                    salesOrderItem,
                                    period: deliveryLog.period,
                                    actualUnitsSold: salesOrderItem.quantity,
                                    unitsLogPosted: unitsDelivered,
                                    extraUnits:
                                        unitsDelivered - salesOrderItem.quantity
                                });
                            }
                            unitsDelivered = salesOrderItem.quantity;
                        }
                        percentRecognized =
                            unitsDelivered / salesOrderItem.quantity;
                    }

                    percentRecognized -= cumulativePercentRecognized;
                }

                if (isNaN(percentRecognized)) throw new Error("I am dead");

                planElement.percentRecognized = percentRecognized;

                cumulativePercentRecognized += percentRecognized;
            }

            updateRatablePlan(plan, productRevenue);
        }
    }
}

export function updatePSPlanPercentageOfCompletion(
    revenueArrangementItem,
    salesOrder,
    errors,
    productRevenue
) {
    let { professionalServiceDelivery } = salesOrder;

    if (professionalServiceDelivery) {
        let soiPSDelivery = professionalServiceDelivery.soiProfessionalServiceDelivery.find(
            (psd) => {
                let itemId =
                    revenueArrangementItem.salesOrderItem.rootId ||
                    revenueArrangementItem.salesOrderItem.id;
                if (psd.salesOrderItemId === itemId) return psd;
            }
        );

        if (soiPSDelivery) {
            let { salesOrderItem, plan: revenuePlan } = revenueArrangementItem;
            let { estimatedTotalCost, estimatedUnitCost } = salesOrderItem;

            let { deliveryLog } = soiPSDelivery;

            deliveryLog.forEach((log) => {
                if (log.unitsDelivered) {
                    log.hourlyRate = estimatedUnitCost;
                    log.costOfUnitsDelivered =
                        log.unitsDelivered * estimatedUnitCost;
                }
            });

            let groupedByPeriod = Utils.agg(
                deliveryLog,
                ["period"],
                ["period"],
                [],
                [],
                [
                    "percentComplete",
                    "unitsDelivered",
                    "costIncurred",
                    "costOfUnitsDelivered"
                ]
            );
            let logsByPeriod = Utils.arrayToObject(
                groupedByPeriod,
                (E) => E.period
            );

            for (let planElement of revenuePlan) {
                const period =
                    planElement.actgPeriod?.period || planElement.period;
                if (!period) throw new Error("Bad plan state: missing period");

                const periodPSD = logsByPeriod[period];

                if (!periodPSD) {
                    planElement.percentRecognized = 0.0;
                    continue;
                }

                planElement.percentRecognized =
                    (periodPSD.costOfUnitsDelivered + periodPSD.costIncurred) /
                    estimatedTotalCost;
            }

            updateRatablePlan(revenuePlan, productRevenue);
        }
    }
}

export function updatePSPlanProportionalPerformance(
    revenueArrangementItem,
    salesOrder,
    revenueArrangement,
    errors,
    productRevenue,
    calendarConfig,
    incrementalPercentComplete,
    orgConfig,
    revenueWithPPA = false,
    computePreModPlan = false
) {
    let { professionalServiceDelivery } = salesOrder;

    let terminatedProductRevenue = undefined;
    let soiPSDelivery = undefined;
    let cummulativePercentRecognized = 0;

    const propPsdByProductCode = orgConfig.find(
        (config) => config.id === "properties/service-delivery-by-product-code"
    );
    const psdByProductCode = propPsdByProductCode && propPsdByProductCode.value;

    if (professionalServiceDelivery) {
        if (psdByProductCode) {
            soiPSDelivery = {
                deliveryLog: revenueArrangementItem.deliveryLogs
            };
        } else {
            soiPSDelivery = professionalServiceDelivery.soiProfessionalServiceDelivery.find(
                (psd) => {
                    let itemId =
                        revenueArrangementItem.salesOrderItem.rootId ||
                        revenueArrangementItem.salesOrderItem.id;
                    if (psd.salesOrderItemId === itemId) return psd;
                }
            );
        }

        if (soiPSDelivery && soiPSDelivery.deliveryLog) {
            let {
                salesOrderItem,
                plan: revenuePlan,
                itemType,
                unrecognizedQuantity
            } = revenueArrangementItem;
            let { salePrice, extendedSalePrice, quantity } = salesOrderItem;
            const modificationDate = revenueArrangement?.modificationDate;

            let { deliveryLog } = soiPSDelivery;

            // This revenue item has a termination event associated with it,
            // we discard delivery logs after termination.

            if (revenueArrangementItem.terminationDate) {
                const { terminationDate } = revenueArrangementItem;

                // Discard delivery logs after termination Date
                deliveryLog = deliveryLog.filter(
                    (log) => terminationDate >= log.logDate
                );
            }

            if (computePreModPlan && modificationDate) {
                // Discard delivery logs after modification Date. So, that only frozen revenue (revenue allocated pre modification) is calculated
                const modificationPeriod = Period.toPeriod(
                    modificationDate,
                    calendarConfig
                );
                deliveryLog = deliveryLog.filter(
                    (log) =>
                        modificationDate >= log.logDate &&
                        Utils.isSameOrBefore(
                            log.effectivePeriod,
                            modificationPeriod
                        )
                );
            }

            let firstPeriod =
                revenuePlan[0].actgPeriod?.period || revenuePlan[0].period;

            if (deliveryLog.length > 0) {
                let percentCompleteMethod =
                    (!Utils.isNumber(deliveryLog[0].unitsDelivered) && true) ||
                    false;

                soiPSDelivery.logMethod = percentCompleteMethod
                    ? "percentComplete"
                    : "unitsDelivered";

                if (
                    itemType == ItemType.SPLIT_UNRECOGNIZED &&
                    modificationDate
                ) {
                    if (soiPSDelivery.logMethod === "unitsDelivered") {
                        quantity = unrecognizedQuantity;
                    }
                    // Discard delivery logs before the modification date
                    deliveryLog = deliveryLog.filter(
                        (log) => log.logDate >= modificationDate
                    );
                }

                if (percentCompleteMethod) {
                    let percentToDate = 0;

                    deliveryLog = deliveryLog.sort(
                        Comparator.getComparator(
                            ["effectivePeriod", "logDate", "timestamp"],
                            [
                                Comparator.forType("period"),
                                Comparator.forType("date"),
                                Comparator.forType("number")
                            ]
                        )
                    );

                    deliveryLog.forEach((log) => {
                        if (incrementalPercentComplete) {
                            // The percentage is already incremental and no need to - percentToDate
                            log.logPercentComplete = log.percentComplete;
                        } else {
                            // percent complete is the client sent percentage
                            // percentToDate is the cumulative percent complete from the last log
                            log.logPercentComplete =
                                log.percentComplete - percentToDate;
                        }

                        log.billableAmount =
                            extendedSalePrice * log.logPercentComplete;

                        percentToDate = log.percentComplete;

                        if (!log.effectivePeriod) {
                            if (log.period < firstPeriod) {
                                log.effectivePeriod = firstPeriod;
                            } else {
                                log.effectivePeriod = log.period;
                            }
                        }
                    });
                } else {
                    deliveryLog.forEach((log) => {
                        log.hourlyRate = log.hourlyRate || salePrice;
                        log.billableAmount =
                            log.unitsDelivered * log.hourlyRate;
                        if (!log.effectivePeriod) {
                            if (log.period < firstPeriod) {
                                log.effectivePeriod = firstPeriod;
                            } else {
                                log.effectivePeriod = log.period;
                            }
                        }
                    });
                }

                let groupedByEffAndPostingPeriod = Utils.agg(
                    deliveryLog,
                    ["effectivePeriod", "period"],
                    ["effectivePeriod", "period"],
                    [],
                    [],
                    ["billableAmount", "logPercentComplete", "unitsDelivered"]
                );
                let groupedByEffPeriod = Utils.groupByOnArray(
                    groupedByEffAndPostingPeriod,
                    (E) => E.effectivePeriod
                );

                let totalUnitsReconisedTillDate = 0;
                let totalPercentRecognisedTillDate = 0;

                const newRevenuePlan = [];
                for (let planElement of revenuePlan) {
                    const period =
                        planElement.actgPeriod?.period || planElement.period;
                    if (!period)
                        throw new Error("Bad plan state: missing period");

                    const psdLogs = groupedByEffPeriod[period];
                    if (!psdLogs || !psdLogs.length) {
                        planElement.percentRecognized = 0;
                        newRevenuePlan.push(planElement);
                        continue;
                    }

                    let periodPsdLogs;
                    if (revenueWithPPA == true) {
                        // To ensure that we have at least one non-prior-period-adj transaction in currenct period
                        psdLogs.push({
                            billableAmount: 0,
                            effectivePeriod: period,
                            logPercentComplete: 0,
                            period: period,
                            unitsDelivered: 0
                        });
                        periodPsdLogs = Utils.agg(
                            psdLogs,
                            ["period"],
                            ["period"],
                            [],
                            [],
                            [
                                "billableAmount",
                                "logPercentComplete",
                                "unitsDelivered"
                            ]
                        );
                    } else {
                        periodPsdLogs = Utils.agg(
                            psdLogs,
                            ["effectivePeriod"],
                            ["effectivePeriod"],
                            [],
                            [],
                            [
                                "billableAmount",
                                "logPercentComplete",
                                "unitsDelivered"
                            ]
                        );
                    }
                    const plan = planElement;
                    periodPsdLogs.forEach((periodPSD) => {
                        let planElement = Object.assign({}, plan);
                        if (revenueWithPPA == true) {
                            planElement.effectivePeriod = periodPSD.period;
                        }
                        if (soiPSDelivery.logMethod === "unitsDelivered") {
                            // if we have already recognised full no need to look in to future what happens.
                            if (totalUnitsReconisedTillDate >= quantity) {
                                planElement.percentRecognized = 0;
                            } else {
                                planElement.percentRecognized =
                                    periodPSD.unitsDelivered / quantity;
                            }
                            totalUnitsReconisedTillDate +=
                                periodPSD.unitsDelivered;
                        } else {
                            // if we have already recognised full no need to look in to future what happens.
                            if (totalPercentRecognisedTillDate >= 1) {
                                planElement.percentRecognized = 0;
                            } else {
                                planElement.percentRecognized =
                                    periodPSD.logPercentComplete;
                            }
                            totalPercentRecognisedTillDate +=
                                periodPSD.logPercentComplete;
                        }

                        cummulativePercentRecognized +=
                            planElement.percentRecognized;
                        newRevenuePlan.push(planElement);
                    });
                }
                revenuePlan = newRevenuePlan;
                revenueArrangementItem.plan = newRevenuePlan;

                if (revenueArrangementItem.terminationDate) {
                    // This item is terminated at this point.
                    // Amount that is recognized till termination date is considered to be 100% recognized.
                    revenuePlan.forEach((element) => {
                        // For e.g
                        // 202209 = 0.4 (40%) revenue and 202210 = 0.2 (20%) revenue, total revenue is 0.6 (60%)
                        // Changing to
                        // 202209 = 0.4 / 0.6 = 0.666
                        // 202210 = 0.2 / 0.6 = 0.333
                        element.percentRecognized =
                            element.percentRecognized /
                            cummulativePercentRecognized;
                    });

                    // Would only be the case when new arrangement is created.
                    if (!revenueArrangementItem.terminatedProductRevenue) {
                        revenueArrangementItem.terminatedProductRevenue =
                            revenueArrangementItem.productRevenue *
                            Math.min(cummulativePercentRecognized, 1);
                    }

                    // here product revenue is passed explictly to this function, so we infer terminatedProductRevenue
                    terminatedProductRevenue =
                        productRevenue *
                        Math.min(cummulativePercentRecognized, 1);

                    // When service log is received in an open period after termination period is closed.
                    // terminated revenue may increase or decrease.
                    if (terminatedProductRevenue) {
                        revenueArrangementItem.terminatedProductRevenue = terminatedProductRevenue;
                    }
                }

                if (revenueArrangementItem.terminationDate) {
                    updateRatablePlan(revenuePlan, terminatedProductRevenue);
                } else {
                    updateRatablePlan(revenuePlan, productRevenue);
                }
            }
        }
    }

    // Termination date is present, but NO service is delivered,
    // We zero out the revenue plan in this case.
    if (!soiPSDelivery && revenueArrangementItem.terminationDate) {
        const zeroElement = {
            planAmount: 0,
            percentRecognized: 0,
            cummulativeTotal: 0,
            cummulativePercentRecognized: 0
        };
        revenueArrangementItem.plan.forEach((element) => {
            Object.assign(element, zeroElement);
        });
    }

    return {
        cummulativePercentRecognized
    };
}

export function updatePSPlanRatable(
    revenueArrangementItem,
    salesOrder,
    errors,
    productRevenue,
    calendarConfig,
    incrementalPercentComplete,
    orgConfig,
    revenueWithPPA = false
) {
    const { professionalServiceDelivery } = salesOrder;

    if (!professionalServiceDelivery) {
        return;
    }

    const propPsdByProductCode = orgConfig.find(
        (config) => config.id === "properties/service-delivery-by-product-code"
    );
    const psdByProductCode = propPsdByProductCode && propPsdByProductCode.value;

    let soiPSDelivery;
    if (psdByProductCode) {
        soiPSDelivery = {
            deliveryLog: revenueArrangementItem.deliveryLogs
        };
    } else {
        soiPSDelivery = professionalServiceDelivery.soiProfessionalServiceDelivery.find(
            (psd) => {
                let itemId =
                    revenueArrangementItem.salesOrderItem.rootId ||
                    revenueArrangementItem.salesOrderItem.id;
                if (psd.salesOrderItemId === itemId) return psd;
            }
        );
    }

    if (!soiPSDelivery || !soiPSDelivery.deliveryLog) {
        return;
    }

    let { salesOrderItem, plan, ssp } = revenueArrangementItem;
    let { quantity, product } = salesOrderItem;
    let { deliveryLog } = soiPSDelivery;
    soiPSDelivery.logMethod = "unitsDelivered";

    const getDeliveryEndDateByTerm = (deliveryStartDate, term, unit) => {
        switch (unit) {
            case "Days":
                return Utils.addDays(deliveryStartDate, term);
            case "Months":
                return Utils.addMonths(deliveryStartDate, term);
            case "Years":
                return Utils.formatDate(
                    Utils.addYearsTo(deliveryStartDate, term)
                );
        }
    };

    const firstPeriod = plan[0].actgPeriod?.period || plan[0].period;
    const firstPeriodDate = Period.toDate(firstPeriod);

    const endPeriodPlan = plan[plan.length - 1];

    const getDeliveryStartDate = (log) => {
        return log.logDate < firstPeriodDate ? firstPeriodDate : log.logDate;
    };

    const getDeliveryEndDate = (log) => {
        let deliveryEndDate;
        if (log.serviceEndDate) {
            deliveryEndDate =
                log.serviceEndDate < firstPeriodDate
                    ? firstPeriodDate
                    : log.serviceEndDate;
        } else if (ssp && ssp.deliveryTerm && ssp.unit) {
            deliveryEndDate = getDeliveryEndDateByTerm(
                log.deliveryStartDate,
                ssp.deliveryTerm,
                ssp.unit
            );
        } else if (product && product.term && product.termUnit) {
            deliveryEndDate = getDeliveryEndDateByTerm(
                log.deliveryStartDate,
                product.term,
                product.termUnit
            );
        }
        if (!deliveryEndDate && errors) {
            errors.add(Codes.TRAN_44, {
                salesOrderId: salesOrder.id,
                salesOrderItemId: salesOrderItem.id,
                quantityDelivered: log.unitsDelivered,
                logDate: log.logDate
            });
        }
        return deliveryEndDate;
    };

    let newRevenuePlan = [];
    let totalRevenueRecognized = 0;
    let remainingUnits = quantity;

    const propEndDateInclusive = orgConfig.find(
        (config) => config.id === "properties/ratable_end_date_inclusive"
    );
    const endDateInclusive =
        (propEndDateInclusive && propEndDateInclusive.value) || false;

    deliveryLog = deliveryLog.filter((log) => {
        let result = true;
        if (endDateInclusive) {
            result = Period.isSameOrBefore(log.logDate, salesOrderItem.endDate);
        } else {
            result = Period.isBefore(log.logDate, salesOrderItem.endDate);
        }
        return (
            result &&
            Period.isSameOrBefore(
                log.effectivePeriod,
                endPeriodPlan.actgPeriod.period
            )
        );
    });

    deliveryLog = deliveryLog.sort(
        Comparator.getComparator(
            ["effectivePeriod", "logDate", "timestamp"],
            [
                Comparator.forType("period"),
                Comparator.forType("date"),
                Comparator.forType("number")
            ]
        )
    );

    // We want to track what the latest service period that we need to book revenue to, so keeping this variable up-to-date.
    let servicePeriodExtendedDate = salesOrderItem.endDate;
    deliveryLog.forEach((log) => {
        const item = {
            logDate: log.logDate,
            serviceEndDate: log.serviceEndDate,
            unitsDelivered: log.unitsDelivered,
            salesOrderItem: salesOrderItem
        };
        item.deliveryStartDate = getDeliveryStartDate(item);
        item.deliveryEndDate = getDeliveryEndDate(item);
        if (!item.deliveryEndDate) {
            return;
        }

        let units = log.unitsDelivered || 0;

        // If total number of uints that were send in service delivery input logs exceeds the quantity sold,
        // We ignore additional unit.
        if (remainingUnits == 0 || remainingUnits < units) {
            units = remainingUnits;
        }

        const logRevenue = (units / quantity) * productRevenue;
        totalRevenueRecognized += logRevenue;
        remainingUnits -= units;
        const plan = ArrangementUtils.buildPlan(
            item,
            salesOrder,
            orgConfig,
            logRevenue,
            calendarConfig
        );
        plan.forEach((p) => {
            if (Period.isBefore(p.actgPeriod.period, log.effectivePeriod)) {
                if (revenueWithPPA) {
                    p.effectivePeriod = p.actgPeriod.period;
                }
                p.actgPeriod = Period.toActgPeriod(
                    log.effectivePeriod,
                    calendarConfig
                );
            }
            const planEffectiveDate = p.actgPeriod.effectiveDate;
            // servicePeriodExtendedDate need to be the lates of delivery end date of all the logs.
            servicePeriodExtendedDate =
                planEffectiveDate > servicePeriodExtendedDate
                    ? planEffectiveDate
                    : servicePeriodExtendedDate;

            newRevenuePlan.push(p);
        });
    });

    // We have logs that have service period that goes beyond the deliveryEndDate for the order item and arrangement.
    // Let remember the extended date so we can use it later when generating the transactions.
    if (servicePeriodExtendedDate > revenueArrangementItem.deliveryEndDate) {
        revenueArrangementItem.servicePeriodExtendedDate = servicePeriodExtendedDate;
    }

    // All remaining revenue will be taken in last month,
    // If there is not remaining revenue we do not need to add this.
    if (totalRevenueRecognized != productRevenue) {
        newRevenuePlan.push({
            planAmount: endPeriodPlan.planAmount - totalRevenueRecognized,
            actgPeriod: endPeriodPlan.actgPeriod,
            effectivePeriod: endPeriodPlan.actgPeriod.period
        });
    }

    // Now let fill the gaps, this is where we add planElement for months where there was no delivery.
    // They are all zero unit and zero revenue planElements.
    plan.forEach((p1) => {
        let found = newRevenuePlan.find(
            (p2) => p1.actgPeriod.period === p2.actgPeriod.period
        );

        if (!found) {
            newRevenuePlan.push({
                planAmount: 0,
                actgPeriod: p1.actgPeriod,
                effectivePeriod: p1.actgPeriod.period
            });
        }
    });

    newRevenuePlan = newRevenuePlan.sort(
        Comparator.getComparator(
            [(element) => element.actgPeriod.period],
            [Comparator.forType("period")]
        )
    );

    revenueArrangementItem.plan = newRevenuePlan;
}

export const buildServiceDeliveryRollForward = (
    jobId,
    salesOrder,
    org,
    calendarConfig,
    incrementalPercentConfig,
    isLegacy,
    orgConfig
) => {
    salesOrder.revenueArrangement.sort((ra1, ra2) =>
        ra2.effectiveDate < ra1.effectiveDate ? 1 : -1
    );

    //order arrangements by effective date
    function deliveryLog(salesOrderItem, purchases, raiDeliveryLogs) {
        const { incrementalPurchases } = purchases;
        const percentageToUnitsLog = {};
        const log = {};

        const psdByProductCodeConfig = orgConfig.find(
            (config) =>
                config.id == "properties/service-delivery-by-product-code"
        );
        const psdByProductCode =
            psdByProductCodeConfig && psdByProductCodeConfig.value;

        let d;
        // If psdByProductCode is enabled, get the logs for the item from revenueArrangementItem
        if (psdByProductCode) {
            if (raiDeliveryLogs) {
                d = {
                    logMethod: "unitsDelivered",
                    deliveryLog: raiDeliveryLogs
                };
            }
        } else {
            d = salesOrder.professionalServiceDelivery?.soiProfessionalServiceDelivery?.find(
                (soiPS) => soiPS.salesOrderItemId === salesOrderItem.id
            );
        }

        if (!d) return { log, percentageToUnitsLog };

        let { deliveryLog, logMethod } = cloneDeep(d);
        let priorPeriod;
        const day1Log = deliveryLog[0];
        if (day1Log) {
            const logPeriod = day1Log.effectivePeriod || day1Log.period;
            const _soItemStartActgPeriod = Period.toActgPeriod(
                salesOrderItem.startDate,
                calendarConfig
            );
            if (logPeriod > _soItemStartActgPeriod.period) {
                // I have gap in the deliveryLogs
                const gapPeriods = Period.periodsBetween(
                    _soItemStartActgPeriod.period,
                    logPeriod,
                    true,
                    false
                );

                for (const gp of gapPeriods) {
                    const logToPopulate = Utils.cloneDeep(day1Log);

                    const periodStartDate = Period.toStartOfMonth(gp);

                    logToPopulate.effectivePeriod = gp;
                    logToPopulate.period = gp;
                    logToPopulate.logDate = periodStartDate;
                    logToPopulate.percentComplete = 0.0;
                    logToPopulate.logPercentComplete = 0.0;
                    logToPopulate.billableAmount = 0;
                    logToPopulate.unitsDelivered = 0;
                    logToPopulate.id = shortId.generate();
                    logToPopulate.timestamp =
                        new Date(periodStartDate).getTime() / 1000;

                    deliveryLog.push(logToPopulate);
                }

                deliveryLog = deliveryLog.sort(
                    Comparator.getComparator(
                        ["effectivePeriod", "logDate", "timestamp"],
                        [
                            Comparator.forType("period"),
                            Comparator.forType("date"),
                            Comparator.forType("number")
                        ]
                    )
                );
            }
        }

        let currentQuantity = 0.0;
        // let quantityTillLastPeriod = 0.0
        // let index = 0
        for (let d of deliveryLog) {
            const period = d.effectivePeriod || d.period;
            if (!log[period]) log[period] = 0.0;

            if (logMethod == "percentComplete") {
                if (incrementalPurchases[period] && period != priorPeriod) {
                    currentQuantity += incrementalPurchases[period];
                    priorPeriod = period;
                }

                // always converting percetage to units
                if (incrementalPercentConfig) {
                    log[period] += Number(d.logPercentComplete);
                } else {
                    log[period] = Number(d.logPercentComplete);
                }

                percentageToUnitsLog[period] = log[period] * currentQuantity;
            } else {
                log[period] += Number(d.unitsDelivered);
            }
        }

        return { log, percentageToUnitsLog };
    }

    function getLogMethod(salesOrderItem) {
        const psdByProductCodeConfig = orgConfig.find(
            (config) =>
                config.id == "properties/service-delivery-by-product-code"
        );
        const psdByProductCode =
            psdByProductCodeConfig && psdByProductCodeConfig.value;

        if (psdByProductCode) {
            return "unitsDelivered";
        }

        const d = salesOrder.professionalServiceDelivery?.soiProfessionalServiceDelivery?.find(
            (soiPS) => soiPS.salesOrderItemId === salesOrderItem.id
        );

        if (d) {
            return d.logMethod;
        }
    }

    function purchaseLog(serviceItem, logMethod) {
        let incrementalPurchases = {};
        const incrementalPurchasesByPercentage = {};
        // if this is modification of an order, we will have incremental purchases available at the so service item level
        const soServiceItem = salesOrder.professionalServiceDelivery?.soiProfessionalServiceDelivery?.find(
            (soiPS) => soiPS.salesOrderItemId === serviceItem.salesOrderItemId
        );
        if (soServiceItem && soServiceItem.incrementalPurchases) {
            incrementalPurchases = soServiceItem.incrementalPurchases;
        } else {
            // we don't have incrementalPurchases available at the service line item.
            let cumulativeQuantity = 0;
            for (let arrangement of salesOrder.revenueArrangement) {
                const effectiveActgPeriod = Period.toActgPeriod(
                    arrangement.effectiveDate,
                    calendarConfig
                );
                const item = arrangement.revenueArrangementItem?.find(
                    (a) => a.salesOrderItemId === serviceItem.salesOrderItemId
                );
                if (item) {
                    incrementalPurchases[effectiveActgPeriod.period] =
                        item.salesOrderItem.quantity - cumulativeQuantity;
                    cumulativeQuantity = item.salesOrderItem.quantity;
                }
            }
        }

        let priorClosedPeriods = {};
        if (soServiceItem && soServiceItem.priorClosedPeriods) {
            priorClosedPeriods = soServiceItem.priorClosedPeriods;
        }

        if (logMethod == "percentComplete") {
            // for percentage complete, we need quantity in percentage
            // this way we can calculate the correct numbers
            let priorCumulativeQuantity = 0;
            Object.keys(incrementalPurchases).forEach((p, i) => {
                if (i > 0) {
                    incrementalPurchasesByPercentage[p] =
                        incrementalPurchases[p] /
                        (priorCumulativeQuantity + incrementalPurchases[p]);
                } else {
                    // on day1 it's always 100%
                    incrementalPurchasesByPercentage[p] = 1;
                }

                priorCumulativeQuantity += incrementalPurchases[p];
            });

            // for percentage, I need quantity as well as percentages of quantities
            return {
                incrementalPurchasesByPercentage,
                incrementalPurchases,
                priorClosedPeriods
            };
        }

        // for units delivered, I only need quantity
        return {
            incrementalPurchasesByPercentage,
            incrementalPurchases,
            priorClosedPeriods
        };
    }

    const currentRevenueArrangement = salesOrder.currentRevenueArrangement;

    const rollForward = [];
    const serviceItems =
        currentRevenueArrangement?.revenueArrangementItem.filter(
            (item) =>
                item.recognitionRule.id ===
                    RecognitionRule.PROPORTIONAL_PERFORMANCE ||
                item.recognitionRule.id === RecognitionRule.PERCENT_OF_COMPLETE
        ) || [];

    for (let serviceItem of serviceItems) {
        const {
            plan,
            salesOrderItem,
            deliveryLogs: raiDeliveryLogs
        } = serviceItem;
        const logMethod = getLogMethod(salesOrderItem);

        const purchases = purchaseLog(serviceItem, logMethod);
        const { log, percentageToUnitsLog } = deliveryLog(
            salesOrderItem,
            purchases,
            raiDeliveryLogs
        );

        const endPeriod = plan[plan.length - 1].actgPeriod.period;

        // When we have prior-period-adjustment we will have multiple entries on same actg period
        // with different effective period so to ensure that we have at most 1 entry for each actg period
        const servicePeriods = Array.from(
            new Set(plan.map((p) => p.actgPeriod.period))
        );

        const deliveryMethod =
            logMethod == "unitsDelivered"
                ? "Units Delivered"
                : "Percentage Delivered";

        if (!isLegacy) {
            if (Object.keys(log).length == 0) {
                buildRollForward(
                    org,
                    salesOrder,
                    salesOrderItem,
                    log,
                    servicePeriods,
                    purchases.incrementalPurchases,
                    endPeriod,
                    rollForward,
                    "Units Delivered",
                    purchases.priorClosedPeriods,
                    jobId
                );
                continue;
            }

            if (logMethod == "unitsDelivered") {
                buildRollForward(
                    org,
                    salesOrder,
                    salesOrderItem,
                    log,
                    servicePeriods,
                    purchases.incrementalPurchases,
                    endPeriod,
                    rollForward,
                    deliveryMethod,
                    purchases.priorClosedPeriods,
                    jobId
                );
            } else {
                buildRollForward(
                    org,
                    salesOrder,
                    salesOrderItem,
                    percentageToUnitsLog,
                    servicePeriods,
                    purchases.incrementalPurchases,
                    endPeriod,
                    rollForward,
                    deliveryMethod,
                    purchases.priorClosedPeriods,
                    jobId
                );
            }
        } else {
            // legacy client
            let beg = 0.0;
            let end = 0.0;
            let consumed = 0.0;

            for (let period of servicePeriods) {
                beg = end;
                consumed = log[period] || 0.0;
                const adds = purchases.incrementalPurchases[period]
                    ? purchases.incrementalPurchases[period]
                    : 0.0;
                const expired = period === endPeriod ? end : 0.0;
                end = beg + adds - expired - consumed;

                rollForward.push({
                    OrgId: org.id,
                    Active_Link_Id: 0,
                    "Customer Id": salesOrder.customerId,
                    "Sales Order Id": salesOrder.id,
                    "Sales Order Item Id":
                        salesOrderItem.rootId || salesOrderItem.id,
                    "Product Id": salesOrderItem.product.id,
                    "Actg Period": period,
                    "Beginning Units": beg,
                    "Consumed Units": consumed,
                    "Overage Units": 0.0,
                    "New Units": adds,
                    "Ending Units": end,
                    "Expired Units": expired,
                    "Beginning Percentage": 0.0,
                    "New Percentage": 0.0,
                    "Consumed Percentage": 0.0,
                    "Overage Percentage": 0.0,
                    "Expired Percentage": 0.0,
                    "Ending Percentage": 0.0,
                    "Cumulative Consumed Percentage": 0.0,
                    "Cumulative Overage Percentage": 0.0,
                    "Log Method": deliveryMethod,
                    "Job Id": jobId,
                    LastModifiedDate: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " ")
                });
            }
        }
    }

    return rollForward;
};

function buildRollForward(
    org,
    salesOrder,
    salesOrderItem,
    log,
    servicePeriods,
    purchases,
    endPeriod,
    rollForward,
    deliveryMethod,
    priorClosedPeriods,
    jobId
) {
    let beg = 0.0;
    let end = 0.0;
    let totalQuantityTillPeriod = 0.0;
    let cumulativeOverageUnits = 0.0;
    let totalComulativeUnitsConsumedUntilLastPeriod = 0.0;

    let totalCumulativeUnitsDelivered = 0.0;
    let totalCumulativeUnitsDeliveredTillLastPeriod = 0.0;
    let priorQuantity = 0.0;
    let priorExpired = 0.0;
    let totalCumulativePriorExpired = 0.0;

    let totalComulativePercentConsumed = 0.0;
    let totalCumulativeOverage = 0.0;

    let index = 0;
    for (let period of servicePeriods) {
        beg = end;

        let consumed = 0.0;
        let deliveredUnits = log[period] || 0.0;
        totalCumulativeUnitsDelivered += deliveredUnits;

        let adds = purchases[period] ? purchases[period] : 0.0;
        totalQuantityTillPeriod += adds;

        let overageUnits = 0.0;
        let expired = 0.0;

        if (index > 0 && adds > 0) {
            // I had somethind that was overage, but now it seems
            // i can consume it. how much is what we are going to determine
            let unitsToConsumeThisPeriod =
                totalCumulativeUnitsDeliveredTillLastPeriod -
                totalComulativeUnitsConsumedUntilLastPeriod;
            unitsToConsumeThisPeriod += deliveredUnits;

            // 1.0 + 0.166 - 0.9) = 0.2666
            const capacityToConsumeThisPeriod =
                totalQuantityTillPeriod -
                totalComulativeUnitsConsumedUntilLastPeriod;

            if (capacityToConsumeThisPeriod >= unitsToConsumeThisPeriod) {
                consumed = unitsToConsumeThisPeriod;
            } else {
                // 0.25 capacityToConsumeThisPeriod
                // 0.3 percToConsumeThisPeriod

                // 0.2 overage + 0.1 currentLog

                consumed = capacityToConsumeThisPeriod;
            }

            overageUnits = deliveredUnits - consumed;
        } else {
            if (totalCumulativeUnitsDelivered <= totalQuantityTillPeriod) {
                // whatever is delivered is consumed
                consumed = deliveredUnits;
            } else {
                // Can I consume something today?
                if (index > 0 && adds < 0) {
                    // quantity has shrinked
                    // Can I consume something today?
                    consumed =
                        deliveredUnits -
                        (totalCumulativeUnitsDelivered -
                            totalQuantityTillPeriod -
                            cumulativeOverageUnits);

                    overageUnits = deliveredUnits;
                } else {
                    if (deliveredUnits) {
                        overageUnits =
                            totalCumulativeUnitsDelivered -
                            totalQuantityTillPeriod -
                            cumulativeOverageUnits;
                    }
                    consumed = deliveredUnits - overageUnits;
                }
            }
        }

        cumulativeOverageUnits += overageUnits;
        totalComulativeUnitsConsumedUntilLastPeriod += consumed;
        totalCumulativeUnitsDeliveredTillLastPeriod += deliveredUnits;

        if (priorExpired) {
            end =
                totalQuantityTillPeriod -
                totalComulativeUnitsConsumedUntilLastPeriod;
            expired = beg + adds - end - consumed;
        } else {
            end = beg + adds - Math.abs(expired) - consumed;
        }

        if (period == endPeriod) {
            // we have expired units now
            // consumed this period and everything until last
            end = 0;
            expired = beg + adds - end - consumed;
        }

        if (
            priorClosedPeriods &&
            period == priorClosedPeriods[period] &&
            period != endPeriod
        ) {
            expired = end;
            end = 0;
            priorExpired = expired;
        }

        let newPercentage = adds / totalQuantityTillPeriod;
        const begPec = beg / priorQuantity;
        const addPerc = adds / totalQuantityTillPeriod;

        if (index > 0 && adds && beg) {
            newPercentage =
                addPerc +
                (-1 * begPec +
                    (begPec * priorQuantity) / totalQuantityTillPeriod);
        }

        let consumedPercentage = consumed / totalQuantityTillPeriod;
        let overagePercentage = overageUnits / totalQuantityTillPeriod;
        let expiredPercentage = expired / totalQuantityTillPeriod;
        let endPercentage = end / totalQuantityTillPeriod;

        if (consumedPercentage) {
            totalComulativePercentConsumed += consumedPercentage;
        }

        if (overagePercentage) {
            totalCumulativeOverage += overagePercentage;
        }

        priorQuantity = totalQuantityTillPeriod;

        const toPush = {};
        toPush["OrgId"] = org.id;
        toPush["Active_Link_Id"] = 0;
        toPush["Customer Id"] = salesOrder.customerId;
        toPush["Sales Order Id"] = salesOrder.id;
        toPush["Sales Order Item Id"] =
            salesOrderItem.rootId || salesOrderItem.id;
        toPush["Product Id"] = salesOrderItem.product.id;
        toPush["Actg Period"] = period;
        toPush["Beginning Units"] = beg;
        toPush["Consumed Units"] = consumed;
        toPush["New Units"] = adds;
        toPush["Ending Units"] = end;
        toPush["Overage Units"] = overageUnits;
        toPush["Expired Units"] = expired;
        toPush["Beginning Percentage"] = begPec;
        toPush["New Percentage"] = newPercentage;
        toPush["Consumed Percentage"] = consumedPercentage;
        toPush["Overage Percentage"] = overagePercentage;
        toPush["Expired Percentage"] = expiredPercentage;
        toPush["Ending Percentage"] = endPercentage;
        toPush["Cumulative Consumed Percentage"] = deliveredUnits
            ? totalComulativePercentConsumed
            : 0.0;
        toPush["Cumulative Overage Percentage"] = deliveredUnits
            ? totalCumulativeOverage
            : 0.0;
        toPush["Log Method"] = deliveryMethod;
        toPush["Job Id"] = jobId;
        toPush["LastModifiedDate"] = new Date()
            .toISOString()
            .slice(0, 19)
            .replace("T", " ");

        rollForward.push(toPush);

        index++;
    }
}

/**
 * Populates DeliveryLogs at RevenueArrangementItem level
 * @param revenueArrangement
 * @param salesOrder
 * @param orgConfig
 * @param errors
 * @returns
 */
export const populateDeliveryLogs = (
    revenueArrangement,
    salesOrder,
    orgConfig,
    errors
) => {
    if (!salesOrder.professionalServiceDelivery) {
        return;
    }
    // Hold all the logs in unconsumedLogs initially and remove if a log can be attached to an raItem
    // And the final array will have the logs which cannot be assigned to any item
    const unconsumedLogs = {};
    const populateUnconsumedLogs = () => {
        salesOrder.professionalServiceDelivery.soiProfessionalServiceDelivery.forEach(
            (productDeliveryLogs) => {
                let { productId, deliveryLog } = productDeliveryLogs;
                deliveryLog.sort(
                    Comparator.getComparator(
                        ["logDate", "timestamp"],
                        [
                            Comparator.forType("date"),
                            Comparator.forType("number")
                        ]
                    )
                );
                if (
                    revenueArrangement.modificationType ==
                        ModificationType.PROSPECTIVE &&
                    deliveryLog
                ) {
                    deliveryLog.forEach((log) => {
                        // get log's timestamp - * 1000 gives us the milliseconds back
                        const logUpdatedDate = moment(log.timestamp * 1000);
                        const soUpdatedDate = salesOrder.lastUpdateDate;
                        if (
                            log.logDate < revenueArrangement.modificationDate &&
                            (salesOrder.jobId == log.jobId ||
                                Utils.isAfter(logUpdatedDate, soUpdatedDate))
                        ) {
                            // if the logDate is before the modificationDate and the log is received in the currentJob or
                            // in any future job, add a warning that the log cannot be consumed
                            errors.add(Codes.PSD_05, {
                                salesOrderId: salesOrder.id,
                                ...log
                            });
                        }
                    });
                    deliveryLog = deliveryLog.filter(
                        (log) =>
                            log.logDate >= revenueArrangement.modificationDate
                    );
                }
                unconsumedLogs[productId] = deliveryLog.map((log) => {
                    return {
                        effectivePeriod: log.effectivePeriod,
                        logDate: log.logDate,
                        period: log.period,
                        salesOrderItemId: log.salesOrderItemId,
                        serviceEndDate: log.serviceEndDate,
                        unitsDelivered: log.unitsDelivered,
                        serviceDeliveryId: log.serviceDeliveryId
                    };
                });
            }
        );
    };
    const splitLog = (log, units1, units2) => {
        const log1 = Utils.cloneDeep(log);
        log1.unitsDelivered = units1;
        const log2 = Utils.cloneDeep(log);
        log2.unitsDelivered = units2;
        return [log1, log2];
    };
    const isRolloverUnusedUnits = (soItem) => {
        if (soItem.rolloverUnusedUnits == null) {
            return soItem.product.rolloverUnusedUnits;
        }
        return soItem.rolloverUnusedUnits;
    };
    const populateExtendedDeliveryEndDate = (raiByProduct) => {
        Object.keys(raiByProduct).forEach((productCode) => {
            const raItems = raiByProduct[productCode];
            let maxEndDate = "2000-01-01";
            raItems.forEach((raItem) => {
                maxEndDate =
                    raItem.deliveryEndDate > maxEndDate
                        ? raItem.deliveryEndDate
                        : maxEndDate;
            });
            raItems.forEach((raItem) => {
                if (isRolloverUnusedUnits(raItem.salesOrderItem)) {
                    raItem.extendedDeliveryEndDate = maxEndDate;
                }
            });
        });
    };
    const attachLogToRaItem = (log, raItem) => {
        raItem.deliveryLogs.push(log);
        const remainingUnits = raItem.remainingUnits - log.unitsDelivered;
        raItem.remainingUnits = remainingUnits > 0 ? remainingUnits : 0;
    };
    const endDateInclusiveConfig = orgConfig.find(
        (config) => config.id === "properties/ratable_end_date_inclusive"
    );
    const endDateInclusive =
        endDateInclusiveConfig && endDateInclusiveConfig.value;

    const compareLogDateWithRaItem = (log, raItem) => {
        const {
            deliveryStartDate: startDate,
            deliveryEndDate,
            extendedDeliveryEndDate
        } = raItem;
        const beforeEndDate = endDateInclusive
            ? Utils.isSameOrBefore
            : Utils.isBefore;
        const startPeriod = Period.toPeriod(startDate);
        const isAfterStartPeriod = Period.isSameOrAfter(
            log.effectivePeriod,
            startPeriod
        );
        const isBeforeEndDate = beforeEndDate(
            log.logDate,
            extendedDeliveryEndDate || deliveryEndDate
        );
        return [isAfterStartPeriod, isBeforeEndDate];
    };

    let discardedLogs = {};

    const addToDiscardedLogs = (log, productCode) => {
        if (!discardedLogs[productCode]) {
            discardedLogs[productCode] = [];
        }
        discardedLogs[productCode].push(log);
    };

    // Populate unconsumedLogs at revenueArrangement [productCode -> deliveryLogs]
    populateUnconsumedLogs();

    const activeRaitems = revenueArrangement.revenueArrangementItem.filter(
        (raItem) =>
            !raItem.salesOrderItem.isDeleted &&
            !ItemType.isFrozen(raItem.itemType)
    );
    const raiByProduct = Utils.groupByOnArray(
        activeRaitems,
        (item) => item.salesOrderItem.product.code
    );

    // Populate maximum service end date to all revenueArrangementItems if rolloverUnusedUnits is configured
    populateExtendedDeliveryEndDate(raiByProduct);

    // Clear deliveryLogs mapped to the existing revenueArrangementItems
    revenueArrangement.revenueArrangementItem.forEach((raItem) => {
        raItem.deliveryLogs = [];
        raItem.remainingUnits = raItem.salesOrderItem.quantity;
    });

    // "- Add a new flag to Product PO, Track Base Product Usage (trackBaseProductUsage)
    for (let soi of salesOrder.salesOrderItem) {
        let product = soi.product;
        if (product.entityType == "PO" && product.trackBaseProductUsage) {
            const baseDeliveryLogs = cloneDeep(unconsumedLogs[product.parent]);
            unconsumedLogs[product.id] = baseDeliveryLogs;
        }
    }

    Object.keys(raiByProduct).forEach((productCode) => {
        const raItems = raiByProduct[productCode];
        const deliveryLogs = unconsumedLogs[productCode];
        raItems.sort(
            Comparator.getComparator(
                [(raItem) => raItem.deliveryStartDate],
                [Comparator.forType("date")]
            )
        );
        if (deliveryLogs && deliveryLogs.length > 0) {
            for (const raItem of raItems) {
                // Process logs until the current revenueArrangementItem has units available to consume logs
                for (
                    let i = 0;
                    i < deliveryLogs.length && raItem.remainingUnits > 0;

                ) {
                    let { remainingUnits } = raItem;
                    const log = deliveryLogs[i];
                    // When the log is at salesOrderItemId, attach the log to the specific revenueArrangementItem
                    if (log.salesOrderItemId) {
                        const soiRaItems = raItems.filter(
                            (raItem) =>
                                raItem.salesOrderItemId == log.salesOrderItemId
                        );
                        const soiRaItem = soiRaItems[0];
                        deliveryLogs.splice(i, 1);
                        attachLogToRaItem(log, soiRaItem);
                    } else {
                        // If the logDate does not occur with in the RAI delivery dates,
                        //    the log cannot be consumed by the current revenueArrangementItem
                        const [
                            isAfterStartPeriod,
                            isBeforeEndDate
                        ] = compareLogDateWithRaItem(log, raItem);
                        if (!isBeforeEndDate) {
                            break;
                        }
                        deliveryLogs.splice(i, 1);
                        if (!isAfterStartPeriod) {
                            addToDiscardedLogs(log, productCode);
                            continue;
                        }
                        if (log.unitsDelivered <= remainingUnits) {
                            attachLogToRaItem(log, raItem);
                        } else {
                            // log cannot be consumed completely, will split the logs
                            const [log1, log2] = splitLog(
                                log,
                                remainingUnits,
                                log.unitsDelivered - remainingUnits
                            );
                            attachLogToRaItem(log1, raItem);
                            // rolloverUnusedUnits is enabled, add the log to the start of the list to be consumed by other items
                            if (isRolloverUnusedUnits(raItem.salesOrderItem)) {
                                deliveryLogs.unshift(log2);
                            } else {
                                addToDiscardedLogs(log2, productCode);
                            }
                        }
                    }
                }
            }
        }
    });

    Object.keys(discardedLogs).forEach((productCode) => {
        unconsumedLogs[productCode].unshift(...discardedLogs[productCode]);
    });

    discardedLogs = {};
    // Attach overage units to the raItem with the least startDate
    // and populate errors for all logs which cannot be assigned to any item
    Object.keys(raiByProduct).forEach((productCode) => {
        const raItems = raiByProduct[productCode];
        const deliveryLogs = unconsumedLogs[productCode];
        if (deliveryLogs && deliveryLogs.length > 0) {
            for (const raItem of raItems) {
                for (let i = 0; i < deliveryLogs.length; ) {
                    const log = deliveryLogs[i];
                    const [
                        isAfterStartPeriod,
                        isBeforeEndDate
                    ] = compareLogDateWithRaItem(log, raItem);
                    if (!isBeforeEndDate) {
                        break;
                    }
                    deliveryLogs.splice(i, 1);
                    if (!isAfterStartPeriod) {
                        addToDiscardedLogs(log, productCode);
                        continue;
                    }
                    raItem.deliveryLogs.push(log);
                }
            }
        }
    });
    if (errors) {
        Object.keys(discardedLogs).forEach((productCode) => {
            unconsumedLogs[productCode].push(...discardedLogs[productCode]);
        });
        Object.keys(unconsumedLogs).forEach((productCode) => {
            unconsumedLogs[productCode].forEach((log) => {
                errors.add(Codes.TRAN_45, {
                    salesOrderId: salesOrder.id,
                    ...log
                });
            });
        });
    }
};
